import classnames from '@helpers/misc/classnames'
import React from 'react'
import InformativeStamps from 'src/components/InformativeStampsV2'

import {ProductCardOrientation} from '..'

import {ProductWithStockInfo} from '../../../hooks/useAddStockInfoToProducts.main'
import ProductPrice from './ProductPrice'
export interface Props {
  product: ProductWithStockInfo
  cardOrientation: ProductCardOrientation
  hidePrice: boolean
  compact: boolean
}
function ProductDescriptionSection(props: Props) {
  const {product, cardOrientation, hidePrice, compact} = props
  const {name, description, isOutOfStock, availabilityAt} = product
  const disabledProduct = isOutOfStock || !availabilityAt?.available
  const informativeStampsList = product?.informativeStamps?.slice(0, 3)
  const isHorizontal = cardOrientation === 'horizontal'
  const dimensions = isHorizontal
    ? 'h-40 w-[60%] sm:w-56'
    : compact
      ? 'h-[118px] w-full'
      : 'h-32 w-full'
  const padding = isHorizontal ? 'p-3.5 pr-3' : compact ? 'p-3' : 'p-3.5'
  const titleClamp = isHorizontal || compact ? 'line-clamp-2' : 'line-clamp-1'
  const descriptionClamp = isHorizontal ? 'line-clamp-3' : 'line-clamp-2'

  return (
    <div className={classnames('flex flex-col justify-between', dimensions, padding)}>
      <div
        className={classnames('overflow-hidden text-ellipsis break-words', {
          'opacity-40': disabledProduct,
        })}
      >
        <div className="font-semibold text-base flex flex-row items-center cursor-pointer">
          <span className={titleClamp}> {name}</span>
          <span className="ml-1 flex-none">
            <InformativeStamps stamps={informativeStampsList} iconOnly={true} />
          </span>
        </div>
        {!compact && (
          <p
            className={classnames(
              'mt-0.5 text-xs text-ellipsis dark:text-neutral-300 whitespace-pre-wrap',
              descriptionClamp,
            )}
          >
            {description}
          </p>
        )}
      </div>
      <ProductPrice product={product} hidePrice={hidePrice} compact={compact} />
    </div>
  )
}
export default ProductDescriptionSection
