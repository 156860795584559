import DeliveryWizard from '@components/DeliveryWizard'
import {useUserPreferencesPartial} from '@data/queries/preferences/userPreferences.main'
import classnames from '@helpers/misc/classnames'
import productRequiredModifiers from '@helpers/misc/productWithRequiredModifiers'
import useOpenProductModal from '@hooks/useOpenProductModal'
import useAdd from '@page-components/Order/Products/ProductsContent/SelectProduct/Inner/useAdd'
import React, {useCallback, useState} from 'react'

import {useTimedToggle} from '@hooks/useTimedToggle'
import {ProductWithStockInfo} from '../../hooks/useAddStockInfoToProducts.main'
import AddProductButton from './components/AddProductButton'
import AddedProductNotification from './components/AddedProductNotification'
import Badge from './components/Badges'
import ProductDescriptionSection from './components/ProductDescriptionSection'
import ProductImage from './components/ProductImage'

interface ProductCardProps {
  product: ProductWithStockInfo
  onClick: () => void
  menuId: string
  hidePrice: boolean
  href: string
  description: string
  cardType: ProductCardType
}
export type ProductCardType =
  | 'row'
  | 'row-inverse'
  | 'vertical-wide'
  | 'vertical-compact'
  | 'row-fast'
  | 'row-inverse-fast'
  | 'vertical-wide-fast'
  | 'vertical-compact-fast'

export type ProductCardOrientation = 'horizontal' | 'vertical'

function CardProduct(props: Readonly<ProductCardProps>) {
  const preferences = useUserPreferencesPartial()
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)
  const {product, onClick, menuId, hidePrice, href} = props
  const cardType = props.cardType
  const {isOutOfStock, availabilityAt} = product
  const add = useAdd()
  const quickAddToCart = cardType.includes('-fast')
  const requiredModifiers = productRequiredModifiers(product)
  const disabledProduct = isOutOfStock || !availabilityAt?.available
  const isHorizontal = cardType.includes('row')
  const IsHorizontallyReversed = cardType.includes('row-inverse')
  const isVertical = cardType.includes('vertical')
  const isCompact = cardType.includes('vertical-compact')
  const cardOrientation = (isHorizontal ? 'horizontal' : 'vertical') as ProductCardOrientation
  const cardClasses = classnames(
    'relative bg-white rounded-lg flex shadow-[4px_0_20px_0px_rgba(0,0,0,0.08)] text-black justify-between cursor-pointer',
    'dark:bg-white/[.12] dark:text-white',
    {
      'bg-black/1 dark:bg-white/[.05]': disabledProduct,
      'h-40 w-[95dvw] sm:w-96 items-stretch flex-row': isHorizontal && !IsHorizontallyReversed,
      'h-40 w-[95dvw] sm:w-96 items-stretch flex-row-reverse': IsHorizontallyReversed,
      'flex-col h-72 w-[95dvw] sm:w-96 items-center': isVertical && !isCompact,
      'flex-col h-[278px] w-[42dvw] sm:w-44 items-center': isVertical && isCompact,
    },
  )
  const openProductModalWrapper = useOpenProductModal()
  const openProductModal = useCallback(
    () => openProductModalWrapper(product._id, href),
    [openProductModalWrapper, product._id, href],
  )

  const [isActiveNotification, handleProductNotification] = useTimedToggle({activeDuration: 2000})

  const handleAddProduct = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (disabledProduct) return null
    e.preventDefault()
    if (requiredModifiers || !quickAddToCart) {
      onClick()
      openProductModal()
    } else {
      if (!preferences?.data?.userPreferences?.storeId && !preferences.loading) {
        setIsAddressModalOpen(true)
        return
      }
      await add(
        {
          ...props,
          close: () => {},
          menuId: menuId,
        },
        {amount: 1, modifiers: [], comment: ''},
        product,
        {
          missingModifier: false,
        },
      )

      handleProductNotification()
    }
  }

  return (
    // biome-ignore lint/a11y/useValidAnchor: This is a so made crawled by search engines
    <a onClick={openProductModal} className={cardClasses}>
      <ProductImage
        product={product}
        disabledProduct={disabledProduct}
        cardOrientation={cardOrientation}
        horizontallyReversed={IsHorizontallyReversed}
      />
      <ProductDescriptionSection
        product={product}
        hidePrice={hidePrice}
        cardOrientation={cardOrientation}
        compact={isCompact}
      />
      <Badge
        product={product}
        isOutOfStock={isOutOfStock}
        unavailable={!availabilityAt?.available}
        cardOrientation={cardOrientation}
        horizontallyReversed={IsHorizontallyReversed}
        compact={isCompact}
      />
      <AddProductButton handleAddProduct={handleAddProduct} disabledProduct={disabledProduct} />
      {isAddressModalOpen && (
        <DeliveryWizard.AsModal
          isOpen={isAddressModalOpen}
          onClose={() => {
            setIsAddressModalOpen(false)
          }}
          actionLocation="quick-add-product"
        />
      )}
      <AddedProductNotification active={isActiveNotification} />
    </a>
  )
}

export default CardProduct
