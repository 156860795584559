import {ProductCardOrientation} from '../..'

import {ProductWithStockInfo} from '../../../../hooks/useAddStockInfoToProducts.main'
import DiscountBadge from './Discount'
import OutOfStockBadge from './OutOfStock'
import UnavailableBadge from './Unavailable'
export interface BadgeProps {
  product: ProductWithStockInfo
  unavailable: boolean
  isOutOfStock: boolean
  horizontallyReversed: boolean
  cardOrientation: ProductCardOrientation
  compact?: boolean
}
function Badge(props: BadgeProps) {
  const {isOutOfStock, unavailable} = props

  if (isOutOfStock) return <OutOfStockBadge {...props} />
  if (unavailable) return <UnavailableBadge {...props} />
  return <DiscountBadge {...props} />
}

export default Badge
