import queryObjectToString from '@helpers/misc/queryObjectToString'
import {useRouter} from 'next/router'
import slugify from 'slugify'

import useIsSpecialLayout from './useIsSpecialLayout'
import useWebsiteId from './useWebsiteId'

interface Product {
  _id?: string
  name?: string
}
export default function useProductLink(product: Product, opts: {skipRedirection?: boolean} = {}) {
  const layout = useIsSpecialLayout()
  const websiteId = useWebsiteId()
  const router = useRouter()
  if (opts.skipRedirection) return '#'

  const query = queryObjectToString(router.query)
  const queryStr = query ? `?${query}` : ''
  const slug = slugify(product.name, {
    lower: true,
    strict: true,
  })

  if (layout === 'chat') {
    return `/chat/${websiteId}/order/${product._id}/${slug}${queryStr}`
  }

  return `/pedir/${product._id}/${slug}${queryStr}`
}
