import React from 'react'
import PromotionTag from '@components/PromotionTag'
import FormatNumber from '@i18n/formatNumber/Component'
import useInitialData from '@page-components/Layout/useInitialData'

import styles from '../styles.module.css'

const renderPriceWithDiscounts = props => {
  if (props.hidePrice) return null
  const {finalPrice, basePrice} = props.product.availabilityAt
  return (
    <div>
      <div className={styles.pastPrice}>
        <FormatNumber value={basePrice} />
      </div>
      <div className={styles.price}>
        <FormatNumber value={finalPrice} />
      </div>
    </div>
  )
}

export default function Price(props) {
  const {product} = props
  const {availabilityAt, promotions} = product
  const {website} = useInitialData()
  const priceStyles = website.layout === 'tiramisu' ? styles.tiramisuPrice : styles.price

  if (!availabilityAt || props.hidePrice || !availabilityAt.finalPrice) return null
  const {hasDiscount, finalPrice, available, notAvailableMessage} = availabilityAt
  if (!available) return <div className={styles.notAvailable}>{notAvailableMessage}</div>
  if (hasDiscount) return renderPriceWithDiscounts(props)
  if (promotions?.length)
    return (
      <div>
        <PromotionTag product={product} />
      </div>
    )
  return (
    <div className={priceStyles}>
      <FormatNumber value={finalPrice} />
    </div>
  )
}
