import classnames from '@helpers/misc/classnames'
import selectPriceForProduct from '@helpers/misc/selectPriceForProduct'
import FormatNumber from '@i18n/formatNumber/Component'
import PRICING_TYPES from '@page-components/Order/CategoryPage/Content/Products/ProductCard/pricingTypes'
import {ProductWithStockInfo} from '../../../hooks/useAddStockInfoToProducts.main'

export interface Props {
  product: ProductWithStockInfo
  hidePrice: boolean
  compact: boolean
}

export default function ProductPrice(props: Props) {
  const {product, hidePrice, compact} = props
  if (hidePrice) return null

  const pricing = selectPriceForProduct(product)
  const flexDirection = compact ? 'flex-col sm:flex-row' : 'flex-row'
  return (
    pricing && (
      <div
        className={classnames('flex gap-x-2 text-sm', flexDirection, {
          'opacity-40': product.isOutOfStock,
        })}
      >
        {[PRICING_TYPES.normal, PRICING_TYPES.discount].includes(pricing.type as PRICING_TYPES) && (
          <div>
            <FormatNumber value={pricing.price} />
          </div>
        )}
        {pricing.type === PRICING_TYPES.discount && (
          <div className="text-gray-400 dark:text-neutral-300 line-through">
            <FormatNumber value={pricing.oldPrice} />
          </div>
        )}
      </div>
    )
  )
}
