import classnames from '@helpers/misc/classnames'
import SelectPriceForProduct from '@helpers/misc/selectPriceForProduct'
import useInitialData from '@page-components/Layout/useInitialData'
import PRICING_TYPES from '@page-components/Order/CategoryPage/Content/Products/ProductCard/pricingTypes'

import {BadgeProps} from '.'

function DiscountBadge(props: BadgeProps) {
  const {product, horizontallyReversed} = props
  const pricing = SelectPriceForProduct(product)
  const {design} = useInitialData()
  if (design.design?.hideProductsDiscountBadge) return null

  return (
    <>
      {pricing?.type === PRICING_TYPES.discount && !pricing?.hideDiscountBadge && (
        <span
          className={classnames(
            'absolute top-2 items-center rounded-3xl bg-violet-100 px-1.5 py-0.5 text-sm font-bold text-violet-700 ring-1 ring-inset ring-indigo-700/10',
            {
              'right-2': horizontallyReversed,
              'left-2': !horizontallyReversed
            }
          )}>
          -{Math.round((1 - pricing.price / pricing.oldPrice) * 100)}%
        </span>
      )}
    </>
  )
}

export default DiscountBadge
