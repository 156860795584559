import productListInformationFragment from '@data/fragments/products/productListInformation.main'
import {UseEventsBaseProperties} from '@hooks/useEventsBaseProperties'
import useProductLink from '@hooks/useProductLink'
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react'
import fireEvent from 'react-app-events/lib/fireEvent'

import RowProduct from './Cards/RowProduct'
import CardProduct, {ProductCardType} from './CardsV2'
import Image from './Image'
import Layout from './Layout'
import useCardsV2 from './useCardsV2'

import {ProductWithStockInfo} from '../hooks/useAddStockInfoToProducts.main'
import styles from './styles.module.css'

export interface ProductProps {
  product: ProductWithStockInfo
  menuId: string
  location?: any
  select?: Function
  hidePrice?: boolean
  productsLayout?: string
  productCardDesign?: string
  skipRedirection?: boolean
  productLink?: string
  categoryId?: string
  categoryName?: string
  eventBaseProperties?: UseEventsBaseProperties
}

function Product(props: ProductProps) {
  const {product, productCardDesign, hidePrice, menuId, skipRedirection} = props
  const {isOutOfStock, availabilityAt} = product
  const description = product.description ? product.description.split('\n\n')[0] : ''
  const productLink = useProductLink(product, {skipRedirection: skipRedirection})
  const expUseCardV2 = useCardsV2()
  const isHorizontallyReversed = ['row-inverse', 'row-inverse-fast'].includes(productCardDesign)
  const hasQuickAddProduct = ['row-fast', 'row-inverse-fast'].includes(productCardDesign)

  const onClick = async () => {
    const {hidePrice, select} = props
    if (hidePrice) {
      fireEvent('hiddenPriceProductOpened', product)
      return
    }

    if (select) {
      select()
    }
  }

  const className = [props.productsLayout === 'compact' ? styles.compact : styles.container]
  if (isOutOfStock || (!availabilityAt?.available && !availabilityAt?.notAvailableMessage)) {
    className.push(styles.isOutOfStock)
  }

  const image = <Image product={product} />

  const isRow = ['row', 'row-inverse', 'row-inverse-fast', 'row-fast', 'row-inverse-fast'].includes(
    productCardDesign,
  )

  if (!expUseCardV2 && isRow) {
    return (
      <RowProduct
        image={image}
        onClick={onClick}
        href={productLink}
        product={product}
        description={description}
        menuId={menuId}
        reverse={isHorizontallyReversed}
        fastAddToCart={hasQuickAddProduct}
        hidePrice={hidePrice}
      />
    )
  }
  if (expUseCardV2) {
    const cardType =
      !productCardDesign || productCardDesign === 'default' ? 'row-inverse' : productCardDesign
    return (
      <CardProduct
        onClick={onClick}
        href={productLink}
        product={product}
        description={description}
        menuId={menuId}
        hidePrice={hidePrice}
        cardType={cardType as ProductCardType}
      />
    )
  }

  return (
    <Layout
      href={productLink}
      productId={product._id}
      productsLayout={props.productsLayout}
      image={image}
      parentProps={props}
      className={className}
      onClick={onClick}
    />
  )
}

Product.fragment = productListInformationFragment

export default Product
