import {useSetSelectedProductId} from '@page-components/OrderV2/useSelectedProduct'
import {useRouter} from 'next/router'
import {useCallback} from 'react'

export default function useOpenProductModal() {
  const router = useRouter()
  const overwriteProductId = useSetSelectedProductId()

  const openProductModal = useCallback(
    (productId: string, href: string) => {
      overwriteProductId(productId)
      router.push(href, href, {scroll: false})
    },
    [router],
  )

  return openProductModal
}
