import useInitialData from '@page-components/Layout/useInitialData'
import InformativeStamps from 'src/components/InformativeStamps'

import Price from '../Price'

import TiramisuBtn from './TiramisuBtn'

import styles from '../styles.module.css'

export default function Info(props) {
  const {product, hidePrice} = props
  const description = product.description ? product.description.split('\n\n')[0] : ''
  const informativeStampsList = product?.informativeStamps?.slice(0, 3)
  const {website} = useInitialData()
  const tiramisuLayout = website.layout === 'tiramisu'

  return (
    <div className={styles.info}>
      <div className={styles.nameAndDescription}>
        <span className={styles.name}>{product.name}</span>
      </div>
      <div>
        <InformativeStamps stamps={informativeStampsList} iconOnly={true} />
        <div className={styles.nameAndDescription}>
          {props.productsLayout === 'compact' ? null : (
            <>
              <br />
              <span className={styles.description}>{description}</span>
            </>
          )}
        </div>
      </div>
      <div className={styles.space} />
      {tiramisuLayout ? (
        <TiramisuBtn product={product} hidePrice={hidePrice} />
      ) : (
        <Price product={product} hidePrice={hidePrice} />
      )}
    </div>
  )
}
