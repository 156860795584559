import Image from '@components/Image'
import {GetCategoryProductsQuery} from '@data/__generated__/types.main'
import {FirstCategoryContext} from '@helpers/websites/FirstCategoryContext'
import {useTranslation} from 'next-i18next'
import {useContext} from 'react'
import {MdPhotoLibrary} from 'react-icons/md'

import styles from '../styles.module.css'

export default function ImageComponent(props: {
  product: GetCategoryProductsQuery['category']['paginatedProducts']['items'][0]
}) {
  const {product} = props
  const image = product.images ? product.images[0] : null
  const isInPageFold = useContext(FirstCategoryContext)

  const {t} = useTranslation('website', {
    keyPrefix: 'order.products.productsContent.category.product.image',
  })

  if (!product?.images?.length) {
    return (
      <div className={styles.noImage}>
        <div className={styles.noImageText}>{t('labelNoImage')}</div>
      </div>
    )
  }

  const productImg = () => {
    return (
      <Image
        src={image?.imageSmall}
        alt={product.altImg || product.name}
        title={product.titleImg || product.name}
        priority={isInPageFold}
        layout="fill"
        objectFit="contain"
      />
    )
  }

  return (
    <div className={`${styles.image} ${styles['img-fit-container']}`}>
      {productImg()}
      {product.images.length > 1 && (
        <div className={styles.hasMultipleImages}>
          <MdPhotoLibrary size={18} />
        </div>
      )}
    </div>
  )
}
