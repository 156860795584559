import useInitialData from '@page-components/Layout/useInitialData'
import useProductsLayout from '@page-components/Order/useProductsLayout'

export default function useCardsV2() {
  const {website} = useInitialData()
  const productsLayout = useProductsLayout(website)
  return (
    productsLayout == 'long' &&
    website.businessType === 'restaurant' &&
    website.layout != 'tiramisu'
  )
}
