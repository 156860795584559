import {useTranslation} from 'next-i18next'

import {ProductWithStockInfo} from '../../hooks/useAddStockInfoToProducts.main'
import styles from '../styles.module.css'
interface ProductProps {
  product: ProductWithStockInfo
}

export default function UnavailableOrOutOfStock(props: ProductProps) {
  const {t} = useTranslation('generic', {keyPrefix: 'fields'})
  const {isOutOfStock, availabilityAt} = props.product
  const {notAvailableMessage} = availabilityAt ?? {}
  if ((availabilityAt?.available === true && !isOutOfStock) || notAvailableMessage) return null

  return <div className={styles.outOfStock}>{t(isOutOfStock ? 'outOfStock' : 'notAvailable')}</div>
}
