import Button from '@components/Button'
import DeliveryWizard from '@components/DeliveryWizard'
import usePreferencesForAddressContext from '@components/DeliveryWizard/internals/hooks/usePreferencesForAddressContext.main'
import productRequiredModifiers from '@helpers/misc/productWithRequiredModifiers'
import useOpenProductModal from '@hooks/useOpenProductModal'
import useAdd from '@page-components/Order/Products/ProductsContent/SelectProduct/Inner/useAdd'
import {useTranslation} from 'next-i18next'
import React, {useCallback, useState} from 'react'
import fireEvent from 'react-app-events/lib/fireEvent'

import ProductDescription from './ProductDescription'
import ProductImage from './ProductImage'
import ProductPrice from './ProductPrice'

import {ProductWithStockInfo} from '../../../hooks/useAddStockInfoToProducts.main'
import styles from './styles.module.css'

interface RowProductProps {
  product: ProductWithStockInfo
  onClick: () => void
  menuId: string
  fastAddToCart?: boolean
  hidePrice: boolean
  reverse: boolean
  href: string
  image: React.ReactNode
  description: string
}

export default function RowProduct(props: RowProductProps) {
  const {preferences} = usePreferencesForAddressContext()
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)
  const {product, onClick, menuId, fastAddToCart = false, hidePrice, reverse, href} = props
  const {isOutOfStock, images, availabilityAt} = product
  const add = useAdd()
  const requiredModifiers = productRequiredModifiers(product)

  const openProductModalWrapper = useOpenProductModal()
  const openProductModal = useCallback(() => {
    openProductModalWrapper(product._id, href)
    onClick?.()
  }, [openProductModalWrapper, product._id, href])

  const {t} = useTranslation('website', {
    keyPrefix: 'order.products.productsContent.category.product.cards.rowProduct',
  })

  const OutOfStockOrAvailable = isOutOfStock ? 'labelOutOfStock' : 'labelAddProduct'

  const notAvailableString = () => {
    const maxLength = 36

    if (availabilityAt.notAvailableMessage.length > maxLength) {
      return `${availabilityAt.notAvailableMessage.substring(0, maxLength)}...`
    }
    return availabilityAt.notAvailableMessage
  }

  const unAvailableMessage = availabilityAt?.notAvailableMessage
    ? notAvailableString()
    : t('labelUnavailable')

  const handleButtonClick = async () => {
    if (hidePrice) {
      fireEvent('hiddenPriceProductOpened', product)
      return null
    }
    if (isOutOfStock) return null
    if (requiredModifiers || !fastAddToCart) {
      openProductModal()
    } else {
      if (!preferences?.storeId) {
        setIsAddressModalOpen(true)
        return
      }
      await add(
        {
          ...props,
          close: () => {
            // avoid error on add to cart
          },
          menuId: menuId,
        },
        {amount: 1, modifiers: [], comment: ''},
        product,
        {
          missingModifier: false,
        },
      )
    }
  }

  return (
    <div
      className={`${styles.container} ${
        isOutOfStock || !availabilityAt?.available ? styles.containerOutOfStock : null
      } ${reverse ? styles.reverse : null}`}
    >
      <ProductImage images={images} {...props} />
      <div className={styles.contentContainer}>
        <ProductDescription {...props} />
        <div className={styles.priceContainer}>
          <ProductPrice {...props} />
          <Button
            primary
            onClick={handleButtonClick}
            disabled={isOutOfStock || !availabilityAt?.available}
          >
            {!availabilityAt?.available ? unAvailableMessage : t(OutOfStockOrAvailable)}
          </Button>
        </div>
      </div>
      {isAddressModalOpen ? (
        <DeliveryWizard.AsModal
          isOpen={isAddressModalOpen}
          onClose={() => {
            setIsAddressModalOpen(false)
          }}
          actionLocation="quick-add-product"
        />
      ) : null}
    </div>
  )
}
