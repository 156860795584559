import React from 'react'
import Image from '@components/Image'

import Price from '../../Price'

export default function TiramisuBtn({product, hidePrice}) {
  return (
    <div className={'flex justify-start items-center w-full'}>
      <div className={'flex flex-row'}>
        <Image
          src="/images/tiramisu/shopping-basket.svg"
          alt="shopping basket"
          width={40}
          height={40}
        />
        <div
          className={'flex justify-center items-center font-bold text-vibrant-red text-xl px-2.5'}>
          <Price product={product} hidePrice={hidePrice} />
        </div>
      </div>
    </div>
  )
}
