import classnames from '@helpers/misc/classnames'
import {useTranslation} from 'next-i18next'

import {BadgeProps} from '.'

function OutOfStockBadge(props: BadgeProps) {
  const {t} = useTranslation('website', {
    keyPrefix: 'order.products.productsContent.category.product.cards.rowProduct'
  })
  const {horizontallyReversed} = props

  return (
    <span
      className={classnames(
        'absolute top-2 items-center rounded-3xl bg-gray-400 px-1.5 py-0.5 text-sm font-bold text-white opacity-90',
        'dark:bg-white dark:text-neutral-900',
        {
          'right-2': horizontallyReversed,
          'left-2': !horizontallyReversed
        }
      )}>
      {t('labelOutOfStock')}
    </span>
  )
}

export default OutOfStockBadge
