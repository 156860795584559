import {useTranslation} from 'next-i18next'
import React from 'react'
import {FaCartArrowDown} from 'react-icons/fa'

export interface Props {
  active: boolean
}

function AddedProductNotification(props: Readonly<Props>) {
  const {t} = useTranslation()
  if (!props.active) return null

  return (
    <span className="absolute right-1 top-2 p-2 primaryBackgroundColor rounded px-2 py-1 text-sm font-semibold text-white shadow-sm flex justify-center items-center animate-in-fade-out z-10">
      <FaCartArrowDown className="mr-2" />
      {t('order.products.productsContent.addedProduct')}
    </span>
  )
}

export default AddedProductNotification
