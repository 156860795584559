import {useEffect, useState} from 'react'

interface TimedToggleProps {
  activeDuration?: number
}

export const useTimedToggle = ({
  activeDuration = 300,
}: Readonly<TimedToggleProps>): [boolean, () => void] => {
  const [isActive, setIsActive] = useState(false)
  const [isActiveToggle, setIsActiveToggle] = useState(false)

  useEffect(() => {
    let timeoutId = null

    if (isActiveToggle) {
      setIsActive(true)
      timeoutId = setTimeout(() => {
        setIsActive(false)
        setIsActiveToggle(false)
      }, activeDuration)
    }

    return () => clearTimeout(timeoutId)
  }, [isActiveToggle, activeDuration])

  return [
    isActive,
    () => {
      setIsActiveToggle(true)
    },
  ]
}
