import React from 'react'
import Link from 'next/link'

import styles from './styles.module.css'

export interface Props {
  images: any[]
  hidePrice: boolean
  onClick: () => void
  image: any
  href: string
}

export default function ProductImage(props: Props) {
  const {images, hidePrice, onClick, image, href} = props

  if (!images?.length) return null

  return (
    <div className={styles.image}>
      {hidePrice ? (
        <a onClick={onClick}>{image}</a>
      ) : (
        <Link href={href} prefetch={false} scroll={false} onClick={onClick}>
          {image}
        </Link>
      )}
    </div>
  )
}
