import React from 'react'
import IconImage from '@components/Image'
import Tooltip from '@packages/justo-parts/lib/components/Tooltip'
import icon from 'src/page-components/Order/Products/ProductsContent/Category/constants'

import styles from './styles.module.css'

export default function informativeStamps({stamps, iconOnly}) {
  if (!stamps?.length) return null

  const stampsRow = stamps.map((stamp, index) =>
    iconOnly ? (
      <Tooltip content={icon[stamp].label} key={index}>
        <div className={styles.iconContainer}>
          <IconImage alt={icon[stamp].label} src={icon[stamp].URL} width="16" height="16" />
        </div>
      </Tooltip>
    ) : (
      <div key={index} className={styles.stampContainer}>
        <IconImage alt={icon[stamp].label} src={icon[stamp].URL} width="16" height="16" />
        <div className={styles.label}>{icon[stamp].label}</div>
      </div>
    )
  )

  return <div className={styles.rowContainer}>{stampsRow}</div>
}
