import React from 'react'
import PromotionTag from '@components/PromotionTag'
import selectPriceForProduct from '@helpers/misc/selectPriceForProduct'
import FormatNumber from '@i18n/formatNumber/Component'
import PRICING_TYPES from '@page-components/Order/CategoryPage/Content/Products/ProductCard/pricingTypes'
import Link from 'next/link'

import styles from './styles.module.css'

export interface Props {
  product: any
  onClick: () => void
  hidePrice: boolean
  href: string
}

export default function ProductPrice(props: Props) {
  const {product, onClick, hidePrice, href} = props
  const pricing = selectPriceForProduct(product)

  return pricing &&
  !hidePrice && (
    <div>
      <Link
        href={href}
        prefetch={false}
        scroll={false}
        className={styles.price}
        onClick={onClick}>

        {pricing.type === PRICING_TYPES.normal && <FormatNumber value={pricing.price} />}
        {pricing.type === PRICING_TYPES.discount && (
          <>
            <FormatNumber value={pricing.price} />
            <span>
              <FormatNumber value={pricing.oldPrice} />
            </span>
          </>
        )}
        {pricing.type === PRICING_TYPES.promotions && <PromotionTag product={product} />}

      </Link>
    </div>
  );
}
