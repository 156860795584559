import React from 'react'
import Link from 'next/link'
import InformativeStamps from 'src/components/InformativeStamps'

import styles from './styles.module.css'

export interface Props {
  product: any
  description: string
  onClick: () => void
  hidePrice: boolean
  href: string
}

export default function ProductDescription(props: Props) {
  const {product, description, onClick, hidePrice, href} = props
  const informativeStampsList = product?.informativeStamps?.slice(0, 3)

  return (
    <div className={styles.content}>
      {hidePrice ? (
        <a onClick={onClick}>
          <div className={styles.name}>{product.name}</div>
          <InformativeStamps stamps={informativeStampsList} iconOnly={true} />
          <div className={styles.description}>{description}</div>
        </a>
      ) : (
        (<Link href={href} prefetch={false} scroll={false} onClick={onClick}>

          <div className={styles.name}>{product.name}</div>
          <InformativeStamps stamps={informativeStampsList} iconOnly={true} />
          <div className={styles.description}>{description}</div>

        </Link>)
      )}
    </div>
  );
}
