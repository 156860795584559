import React from 'react'
import {GoPlus} from 'react-icons/go'

export interface Props {
  handleAddProduct: (e: React.MouseEvent<HTMLElement>) => Promise<any>
  disabledProduct: boolean
}

function AddProductButton(props: Props) {
  if (props.disabledProduct) return null

  const {handleAddProduct} = props
  return (
    <span
      className="absolute right-2.5 bottom-2.5 p-2 rounded-full primaryBackgroundColor hover:opacity-80 flex-1 text-white flex items-center justify-center"
      onClick={handleAddProduct}>
      <GoPlus />
    </span>
  )
}

export default AddProductButton
