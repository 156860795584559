export default function SelectPriceForProduct(product) {
  const {availabilityAt, promotions} = product
  if (!availabilityAt) return null
  const {hasDiscount, finalPrice, basePrice, available, notAvailableMessage, hideDiscountBadge} =
    availabilityAt
  if (!available) return {type: 'notAvailable', message: notAvailableMessage}
  if (hasDiscount)
    return {
      type: 'discount',
      price: finalPrice,
      oldPrice: basePrice,
      hideDiscountBadge: hideDiscountBadge
    }
  if (promotions?.length)
    return {type: 'promotions', price: finalPrice, promotionType: promotions[0].promotionType}
  if (!finalPrice) return null
  return {type: 'normal', price: finalPrice}
}
