import classnames from '@helpers/misc/classnames'
import truncate from 'lodash/truncate'
import {useTranslation} from 'next-i18next'

import {BadgeProps} from '.'

function UnavailableBadge(props: BadgeProps) {
  const {t} = useTranslation('website', {
    keyPrefix: 'order.products.productsContent.category.product.cards.rowProduct'
  })
  const {product, horizontallyReversed} = props
  const disabledMessage = product.availabilityAt?.notAvailableMessage ?? t('labelUnavailable')

  return (
    <span
      className={classnames(
        'absolute bottom-2.5 line-clamp-1 w-fit max-w-[88%] items-center rounded-3xl bg-gray-400 px-2 py-1 text-sm font-bold text-white opacity-90',
        horizontallyReversed ? 'left-2.5' : 'right-2.5',
        'dark:bg-neutral-400 dark:text-neutral-900'
      )}>
      {truncate(disabledMessage, {length: 28})}
    </span>
  )
}

export default UnavailableBadge
