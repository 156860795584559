import {useCallback} from 'react'
import useOpenProductModal from '@hooks/useOpenProductModal'
import {ProductProps} from '@page-components/Order/Products/ProductsContent/Category/Product'

import Info from '../Info'
import UnavailableOrOutOfStock from '../UnavailableOrOutOfStock'

import styles from '../styles.module.css'

interface LayoutProps {
  href: string
  productsLayout: string
  image: React.ReactNode
  parentProps: ProductProps
  className: string[]
  onClick: () => void
  productId: string
}

export default function Layout(props: LayoutProps) {
  const {href, productsLayout, productId, image, parentProps, className, onClick} = props

  const openProductModalWrapper = useOpenProductModal()
  const openProductModal = useCallback(() => {
    openProductModalWrapper(productId, href)
    onClick?.()
  }, [openProductModalWrapper, productId, href])

  return productsLayout === 'compact' ? (
    <a onClick={openProductModal} className={className.join(' ')}>
      <UnavailableOrOutOfStock product={parentProps.product} />
      <div className={styles.img}>{image}</div>
      <Info {...parentProps} />
    </a>
  ) : (
    <a className={className.join(' ')} onClick={openProductModal}>
      <UnavailableOrOutOfStock product={parentProps.product} />
      <Info {...parentProps} />
      <div className={styles.img}>{image}</div>
    </a>
  )
}
